import { Controller } from 'stimulus'

export default class ResultsController extends Controller {
  static targets = ['form', 'selectedCategory']

  filterClick (e) {
    const category = e.detail
    if (category === this.selectedCategoryTarget.value) return

    this.selectedCategoryTarget.value = category
    this.formTarget.submit()
  }

  newSearchClick (e) {
    e.stopPropagation()

    const previewController = this.application.getControllerForElementAndIdentifier(document.getElementById('search-preview'), 'search-preview')
    previewController.show()
  }
}
