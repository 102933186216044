import { Controller } from 'stimulus'
import ObsoleteResultError from './obsolete-result-error'

export default class PreviewMobileController extends Controller {
  static targets = ['search', 'form', 'selectedCategory', 'q', 'submit', 'loading', 'preview', 'searchTabs', 'popularSearch']

  get debounceMilliseconds () { return 500 }
  get analyticsMilliseconds () { return 2000 }

  connect () {
    this.resize()
    this.category = this.selectedCategoryTarget.value
  }

  show (e) {
    this.searchTarget.classList.add('rnk-Elastic_Search-visible')
    this.qTarget.focus()

    this.qTarget.focus()
    this.moveCursorToEnd(this.qTarget)
    this.showPopularLinks()

    if (e) e.stopPropagation()
    this.startWaitingForClose()
  }

  hide () {
    this.searchTarget.classList.remove('rnk-Elastic_Search-visible')

    this.stopWaitingForClose()
  }

  resize () {
    const newHeight = window.innerHeight - Math.max(this.searchTarget.getBoundingClientRect().top, 0)
    this.searchTarget.style.maxHeight = newHeight + 'px'
  }

  input () {
    const search = this.newSearch(this.category)
    if (this.canIgnore(search)) return

    if (this.isEmpty(search)) {
      this.collapsePreview()
    } else {
      this.schedulePreview(search)
    }
  }

  tabClick (e) {
    this.selectCategory(e.detail)
  }

  submit (e) {
    e.preventDefault()
    if (this.isEmpty(this.lastSearch)) return

    this.formTarget.submit()
  }

  //
  // Private
  //

  moveCursorToEnd (element) {
    const end = element.value.length
    element.setSelectionRange(end, end)
  }

  startWaitingForClose() {
    this.closingHandler = (e) => this.tryHide(e)
    window.addEventListener('click', this.closingHandler, )
  }

  stopWaitingForClose() {
    window.removeEventListener('click', this.closingHandler)
  }

  tryHide(e) {
    if (this.clickedOutsideSearch(e)) {
      this.hide()
    }
  }

  clickedOutsideSearch(clickEvent) {
    return !this.element.contains(clickEvent.target)
  }

  newSearch (category) {
    this.category = category

    return {
      q: this.qTarget.value.trim(),
      category: this.category
    }
  }

  canIgnore (search) {
    const hasNotChanged = this.lastSearch && (search.q === this.lastSearch.q) && (search.category === this.lastSearch.category)
    this.lastSearch = search
    return hasNotChanged
  }

  isEmpty (search) {
    return !search || search.q === ''
  }

  showPreview (result) {
    const tabsController = this.application.getControllerForElementAndIdentifier(this.searchTabsTarget, 'search-tabs')
    tabsController.show()

    if (this.hasPopularSearchTarget) {
      this.popularSearchTarget.classList.add('hidden')
    }

    this.previewTarget.classList.remove('rnk-Elastic_Preview-collapsed')
    this.previewTarget.innerHTML = result.trim()
  }

  collapsePreview () {
    this.previewTarget.classList.add('rnk-Elastic_Preview-collapsed')

    this.hasPopularSearchTarget && this.showPopularLinks()
  }

  schedulePreview (search) {
    this.cancelPreviousSearch()

    this.debounceTimeout = setTimeout(() => {
      if (this.isStillValid(search)) {
        this.fetchPreview(search)
      }
    }, this.debounceMilliseconds)
  }

  selectCategory (category) {
    const search = this.newSearch(category)
    if (this.canIgnore(search)) return

    if (!this.isEmpty(search)) {
      this.fetchPreview(search)
    }

    this.selectedCategoryTarget.value = search.category
  }

  showPopularLinks () {
    if (this.hasPopularSearchTarget) {
      if (this.popularSearchTarget.innerHTML.trim() === '') {
        this.fetchPopular()
      } else {
        this.popularSearchTarget.classList.remove('hidden')
      }
    }
  }

  fetchPopular () {
    this.popularSearchTarget.dataset.loading = ''

    const url = new URL(location)
    url.pathname = '/ajax/buscador/populares'

    fetch(url)
      .then(response => response.text())
      .then(responseHTML => {
        this.popularSearchTarget.innerHTML = responseHTML
        this.popularSearchTarget.classList.remove('hidden')
        delete this.popularSearchTarget.dataset.loading
      })
      .catch(error => this.debug(error))
  }

  fetchPreview (search) {
    this.showLoading()
    fetch(this.buildURL(search))
      .then(response => {
        this.debug(`Respuesta para ${search.q} ${search.category}`)
        if (!this.isStillValid(search)) return Promise.reject(new ObsoleteResultError('Respuesta obsoleta'))
        return response.text()
      })
      .then(responseText => {
        this.showPreview(responseText)
        this.hideLoading()
        this.scheduleAnalytics(search)
      })
      .catch(error => this.debug(error))
  }

  cancelPreviousSearch () {
    clearTimeout(this.debounceTimeout)
  }

  isStillValid (search) {
    return (this.lastSearch.q === search.q) && (this.lastSearch.category === search.category)
  }

  showLoading() {
    this.submitTarget.classList.add('invisible')
    this.loadingTargets.forEach(loading => loading.classList.remove('invisible'))
  }

  hideLoading() {
    this.submitTarget.classList.remove('invisible')
    this.loadingTargets.forEach(loading => loading.classList.add('invisible'))
  }

  scheduleAnalytics (search) {
    this.cancelPreviousAnalytics()

    this.analyticsTimeout = setTimeout(() => {
      if (this.isStillValid(search)) {
        this.sendAnalytics(search)
      }
    }, this.analyticsMilliseconds)
  }

  cancelPreviousAnalytics () {
    clearTimeout(this.analyticsTimeout)
  }

  sendAnalytics (search) {
    if (typeof ga !== 'function') return

    const url = this.buildAnalyticsURL(search)
    ga('send', 'pageview', url.pathname + url.search)
  }

  buildURL (search) {
    const url = new URL(location)
    url.pathname = '/ajax/buscador/preview'

    const params = { q: search.q }
    if (search.category) params.categoria = search.category
    url.search = new URLSearchParams(params)

    this.debug(url.search.toString())
    return url
  }

  buildAnalyticsURL (search) {
    const url = new URL(location)

    const params = { q: search.q }
    params.categoria = `preview_${search.category || 'todo'}`
    url.search = new URLSearchParams(params)

    return url
  }

  debug (...args) {
    if (args[0] instanceof Error) {
      const error = args[0]
      if (error instanceof ObsoleteResultError) {
        this.debug(error.message)
      } else {
        console.error(error)
      }
    } else {
      if (this.data.get('debug') !== null) console.log(...args)
    }
  }
}
