import { Controller } from 'stimulus'

export default class ResultsMobileController extends Controller {
  static targets = ['form', 'selectedCategory']

  filterClick (e) {
    const category = e.detail
    if (category === this.selectedCategoryTarget.value) return

    this.selectedCategoryTarget.value = category
    this.formTarget.submit()
  }

  submit (e) {
    e.preventDefault()
    this.trySubmit()
  }

  //
  // Private
  //

  selectCategory (category) {
    if (category === this.category) return
    this.category = category

    this.selectedCategoryTarget.value = category

    this.trySubmit()
  }

  trySubmit() {
    if (this.qTarget.value.trim() === '') return

    this.showLoading()
    this.formTarget.submit()
  }

  showLoading() {
    this.submitTarget.classList.add('invisible')
    this.loadingTargets.forEach(loading => loading.classList.remove('invisible'))
  }

  newSearchClick (e) {
    e.stopPropagation()

    const previewController = this.application.getControllerForElementAndIdentifier(document.getElementById('search-preview'), 'search-preview')
    previewController.show()
  }
}
