import { Controller } from 'stimulus'

export default class SearchTabsController extends Controller {
  static targets = ['arrowLeft', 'arrowRight', 'viewport', 'bar', 'tab', 'indicator']

  connect () {
    this.showHideArrows()
    this.selectTab(this.selectedTab)
  }

  show () {
    this.element.classList.remove('hidden')
    this.showHideArrows()
    this.selectTab(this.selectedTab)
  }

  resize () {
    this.showHideArrows()
  }

  tabClick (event) {
    let clickedTab = event.target.dataset.category ? event.target : event.target.closest('[data-category]')
    this.selectTab(clickedTab)

    this.element.dispatchEvent(new CustomEvent('tab-click', {
      detail: clickedTab.dataset.category,
      bubbles: true
    }))
  }

  arrowLeftClick () {
    if (this.scrolling) return

    const nextTab = this.nextLeftTab()
    if (nextTab) {
      this.startScroll(this.leftSide(this.viewportTarget) - this.leftSide(nextTab))
    }
  }

  arrowRightClick () {
    if (this.scrolling) return

    const nextTab = this.nextRightTab()
    if (nextTab) {
      this.startScroll(this.rightSide(this.viewportTarget) - this.rightSide(nextTab))
    }
  }

  transitionEnd () {
    this.finishScroll()
  }

  viewportScroll () {
    this.showHideArrows()
  }

  //
  // Private
  //

  get selectedTab () {
    return this.tabTargets.find(tab => tab.getAttribute('aria-selected') === 'true')
  }

  showHideArrows () {
    const viewportLeft = this.leftSide(this.viewportTarget)
    const viewportRight = this.rightSide(this.viewportTarget)
    const barLeft = this.leftSide(this.barTarget)
    const barRight = this.rightSide(this.lastTabTarget)

    if (barLeft < viewportLeft) {
      this.arrowLeftTarget.classList.remove('invisible')
    } else {
      this.arrowLeftTarget.classList.add('invisible')
    }

    if (viewportRight < barRight) {
      this.arrowRightTarget.classList.remove('invisible')
    } else {
      this.arrowRightTarget.classList.add('invisible')
    }
  }

  selectTab (tabElement) {
    this.tabTargets.forEach(t => t.setAttribute('aria-selected', false))
    tabElement.setAttribute('aria-selected', true)

    this.indicatorLeft = this.leftSide(tabElement) - this.leftSide(this.barTarget)
    this.indicatorTarget.classList.add('rnk-ComponentTabsIndicator-selecting')
    this.indicatorTarget.style.transform = `translateX(${this.indicatorLeft}px)`
    this.indicatorTarget.style.width = `${this.width(tabElement)}px`
  }

  nextLeftTab () {
    const viewportLeft = this.viewportTarget.getBoundingClientRect().left
    const halfwayOut = this.tabTargets.reverse().find(t => this.middle(t) < viewportLeft)
    return halfwayOut || this.tabTargets[0]
  }

  nextRightTab () {
    const viewportRight = this.rightSide(this.viewportTarget)
    const halfwayOut = this.tabTargets.find(t => viewportRight < this.middle(t))
    return halfwayOut || this.lastTabTarget
  }

  startScroll (distance) {
    this.scrolling = true
    this.scrollDistance = distance

    this.barTarget.classList.add('rnk-ComponentTabs-scrolling')
    this.barTarget.style.transform = `translateX(${this.scrollDistance}px)`

    this.indicatorTarget.classList.remove('rnk-ComponentTabsIndicator-selecting')
    this.indicatorTarget.classList.add('rnk-ComponentTabs-scrolling')
    this.indicatorTarget.style.transform = `translateX(${this.indicatorLeft + this.scrollDistance}px)`

  }

  finishScroll () {
    this.indicatorTarget.classList.remove('rnk-ComponentTabs-scrolling')
    this.indicatorTarget.style.transform = `translateX(${this.indicatorLeft}px)`

    this.barTarget.style.transform = 'none'
    this.barTarget.classList.remove('rnk-ComponentTabs-scrolling')

    this.viewportTarget.scrollLeft = this.viewportTarget.scrollLeft - this.scrollDistance
    this.scrolling = false
    this.showHideArrows()
  }

  rightSide (element) {
    return Math.round(element.getBoundingClientRect().right)
  }

  leftSide (element) {
    return Math.round(element.getBoundingClientRect().left)
  }

  middle (element) {
    const rect = element.getBoundingClientRect()
    return Math.round(rect.left + rect.width / 2)
  }

  width (element) {
    return Math.round(element.getBoundingClientRect().width)
  }

  get lastTabTarget() {
    return this.tabTargets[this.tabTargets.length - 1]
  }
}
