import { Controller } from 'stimulus'

export default class FiltersController extends Controller {
  static targets = ['filter']

  filterClick (e) {
    this.element.dispatchEvent(new CustomEvent('filter-click', {
      detail: e.target.dataset.category,
      bubbles: true
    }))
  }
}
