import { Application } from 'stimulus'
import PreviewController from './search/preview-controller'
import ResultsController from './search/results-controller'
import ResultsMobileController from './search/results-mobile-controller'
import SearchTabsController from './search/search-tabs-controller'
import FiltersController from './search/filters-controller'

const application = Application.start()
application.register('search-preview', PreviewController)
application.register('search-results', ResultsController)
application.register('search-results-mobile', ResultsMobileController)
application.register('search-tabs', SearchTabsController)
application.register('filters', FiltersController)
